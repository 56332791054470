.pages-layout {
    padding: 10px;
    display: flex;
    justify-content: center;

    &__content {
        padding: 20px;
    }

    &__heading {
        // text-decoration: underline;
        color: white;
        text-transform: uppercase;
        font-size: 1.6em;
        font-weight: 700;
        margin-bottom: 10px;
    }
}
