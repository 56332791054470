@import '../../assets/scss/utils/all';

.navigation {
	padding: 5px 20px;
	&__items {
		display: flex;
		justify-content: space-between;
		align-items: center;
		&--mobile {
			flex-direction: column;
			margin-top: 20px;
		}
		&__logo {
			width: 150px;
			height: 62px;
			background-image: url(../../assets/img/MindfocusLogo.svg);
			background-size: 100% 100%;
		}
		&__menu {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			// justify-content: space-between;
			&--mobile {
				flex-direction: column;
				flex-wrap: nowrap;
				justify-content: space-around;
			}
			&__item {
				color: $color-secondary;
				text-decoration: none;
				padding: 10px;

				&--contact {
					color: white;
					text-decoration: none;
				}
				&.is-active {
					// color: $color-light-grey;
					&:before {
						content: '';
						position: absolute;
						border-bottom: 4px solid $color-primary;
						width: 100%;
						// calc(80% - 40px);
						bottom: 0;
						left: 0px;
					}
				}
			}
		}
	}
}
