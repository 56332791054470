@import "./assets/scss/utils/all";

.app {
    &__header {
        // width: 80%;
        padding: 0 15px;
        display: flex;
        flex-direction: wrap;
        justify-content: space-between;
        align-items: center;

        // &__logo {
        // 	width: 150px;
        // 	height: 62px;
        // 	background-image: url(~assets/img/MindfocusLogo.svg);
        // 	background-size: 100% 100%;
        // 	float: left;
        // }
   
    }

    &__content {
        margin-top: 64px;
        padding: 30px;
        margin-bottom: 50px;
        min-width: 320px;
    }

    &__background {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;

        // background-image: url(./assets/img/main.jpg);
        // background-size: cover;
        // background-repeat: no-repeat;
        z-index: -1;
    }

    &__footer {
        background: white;
        position: fixed;
        width: 100%;
        padding: 10px;
        bottom: 0;
        text-align: center;
        font-size: 0.75em;
        min-width: 320px;

        &__container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &__tel {
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-decoration: none !important;
                color: $color-tertiary;
            }
        }
    }
}
