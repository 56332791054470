/* =================================
*  VARIABLES SCSS
/* 
 ==================================== */

// ---- fonts ----
$font-light-italic: 'Open Sans Light Italic', sans-serif;
$font-light: 'Open Sans Light', sans-serif;
$font-italic: 'Open Sans Italic', sans-serif;
$font-regular: 'Open Sans Regular', sans-serif;
$font-semi-bold-italic: 'Open Sans SemiBold Italic', sans-serif;
$font-semi-bold: 'Open Sans SemiBold', sans-serif;
$font-bold-italic: 'Open Sans Bold Italic', sans-serif;
$font-bold: 'Open Sans Bold', sans-serif;
$font-extra-bold-italic: 'Open Sans ExtraBold Italic', sans-serif;
$font-extra-bold: 'Open Sans ExtraBold', sans-serif;

// ---- font sizes ----
$font-size-xl: 2em;
$font-size-large: 1.6em;
$font-size-medium: 1.2em;
$font-size-small: 1em;
