.about {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-shrink: 1;
    flex-wrap: wrap;

    // align-items: center;

    &__avatar {
        // float: 'left';
        margin: 10px;
        width: 180px;
        height: 180px;
    }

    &__bacp {
        margin: 10px;
        background: white;
        padding: 10px;
        width: 100%;
        max-width: 280px;
        border-radius: 5px;

        img {
            width: inherit;
        }
    }
}
