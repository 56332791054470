/* =================================
*   BASE SCSS
==================================== */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

body {
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
	background: #fff;
	margin: 0;
	color: #fff;
	&.logged-in {
		.navbar-fixed {
			padding-top: 50px;
		}
	}
}

* {
	outline: none !important;
	box-sizing: border-box;
}

*:focus,
*:active *::selection {
	outline: none !important;
	border-color: inherit;
	-webkit-box-shadow: none;
	box-shadow: none;
}
*::selection {
	background: $color-tertiary;
}
/* Headings
* --------------------------------------- */

h1,
h2,
h3,
h4 {
	font-weight: bold;
	color: #fff;
}

h2 {
	text-decoration: underline $color-primary;
	&::after {
		content: ':';
	}
}

#root {
	display: flex;
	flex-wrap: wrap;
	min-height: 100vh;
	& > div {
		width: 100%;
	}
}

p {
	margin-top: 5px;
	font-size: 1.2em;
	color: #fff;
	font-family: $font-regular;
	&::selection {
		background: $color-tertiary;
	}
}

@media (max-width: 800px) {
	p {
		font-size: 1em;
	}
}
