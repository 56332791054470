@import "../assets/scss/utils/all";

.home {
    display: flex;
    flex-direction: column;
    align-items: center;

    & p {
        text-shadow: 0px 0px 2px black;
    }

    &__btns {
        margin-top: 20px;
        width: 90%;
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 10px;
    }
}
