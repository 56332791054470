/* =================================
*	Color SCSS
* @version: 1.0
* @author: Jay Sukhija
==================================== */

/* Colors
==================================== */

// Variable overrides
$color-primary: #afd275;
$color-secondary: #7e685a;
$color-tertiary: #e7717d;
